import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { IConfiguracoes } from "./configuracoes.types";

@Injectable({ providedIn: "root" })
export class AppConfigService {
  private _config$ = new BehaviorSubject<IConfiguracoes>(null);

  constructor(private _http: HttpClient) {
    this.loadConfig();
  }

  loadConfig() {
    this._http
      .get<IConfiguracoes>("assets/config/configuracoes.json")
      .subscribe((res: IConfiguracoes) => this._config$.next(res));
  }

  //
  //
  // Accessors
  get config$(): Observable<IConfiguracoes> {
    return this._config$.asObservable();
  }

  get config(): IConfiguracoes {
    return this._config$.value;
  }
}
