import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent
} from "@fuse/components/navigation";
import { FuseMediaWatcherService } from "@fuse/services/media-watcher";
import { NavigationService } from "app/core/navigation/navigation.service";
import { Navigation } from "app/core/navigation/navigation.types";
import { AuthAdminService } from "app/modules/admin/auth/auth-admin.service";
import { Subject, takeUntil } from "rxjs";
import { environment as env } from "environments/environment";
import { UserToken } from "app/modules/admin/auth/permission/rule";import { IConfiguracoes } from "app/core/config/configuracoes.types";
import { AppConfigService } from "app/core/config/app-config.service";
import { MatDialog } from "@angular/material/dialog";
import { AlterarSenhaComponent } from "app/modules/admin/painel/alterar-senha/containers/alterar-senha.component";
;

@Component({
  selector: "compact-layout",
  templateUrl: "./compact.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class CompactLayoutComponent implements OnInit, OnDestroy {
  isScreenSmall: boolean;
  navigation: Navigation;
  version = env.version;
  userToken: UserToken;
  configs: IConfiguracoes;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _router: Router,
    private _authService: AuthAdminService,
    private _navigationService: NavigationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private _configService: AppConfigService,
    private _matDialog: MatDialog, 
  ) {}


  get currentYear(): number {
    return new Date().getFullYear();
  }

  ngOnInit(): void {
    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes("md");
      });

      this.userToken = this._authService.userToken;

    this._configService.config$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((configs: IConfiguracoes) => this.configs = configs);
  }

  openDialogAlterarSenha(): void {
    this._matDialog.open(AlterarSenhaComponent, {
      panelClass: ['w-full', 'max-w-lg']
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
        name
      );

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  logOut() {
    this._authService.signOut()
    .subscribe(() => {
        this._router.navigate(['/admin/login']);
    });
  }
}
